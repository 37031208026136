// pages
import Dashboard from "screens/Dashbord";
import AboutUs from "screens/Dashbord/AboutUs";

import Chapter from "screens/SubjectManager/Chapter/index";
import CreateChapter from "screens/SubjectManager/Chapter/Create";
import ChapterDetail from "screens/SubjectManager/Chapter/Detail";

import Topic from "screens/SubjectManager/Topic/index";
import CreateTopic from "screens/SubjectManager/Topic/Create";
import TopicDetail from "screens/SubjectManager/Topic/Detail";

import Question from "screens/SubjectManager/Question";
import CreateQuestion from "screens/SubjectManager/Question/Create";
import QuestionDetail from "screens/SubjectManager/Question/Detail";

import MCQS from "screens/SubjectManager/MCQS";
import CreateMCQS from "screens/SubjectManager/MCQS/Create";
import MCQDetail from "screens/SubjectManager/MCQS/Detail";

import Papers from "screens/PaperManager/Paper";
import CreatePaper from "screens/PaperManager/Paper/Create/index";
import PaperDetail from "screens/PaperManager/Paper/Detail";

import PaperReports from "screens/Report/Paper";

import User from "screens/Admin/User";
import CreateUser from "screens/Admin/User/Create";

import Campus from "screens/Admin/Campus";
import CreateCampus from "screens/Admin/Campus/Create";

import CampusUser from "screens/Admin/CampusUser";
import CreateCampusUser from "screens/Admin/CampusUser/Create";
import CreateSchoolTeacherBSS from "screens/Admin/CampusUser/CreateSchoolTeacherBSS";

import Complaint from "screens/Admin/Complaint";
import RateReview from "screens/Admin/RateReview";
import AskQuestion from "screens/Admin/AskQuestion";

// Register Feedback
import FeedbackComplaint from "screens/Feeback/Complaint";
import FeedbackRateReview from "screens/Feeback/RateReview";
import FeedbackAskQuestion from "screens/Feeback/AskQuestion";

//Take Tour
import LodgeComplaintTutorial from "screens/TakeTour/lodgeComplaint";
import RateReviewTurotial from "screens/TakeTour/RateReview";
import PaperGenerationTurotial from "screens/TakeTour/GeneratePaper";
import ReportsTutorial from "screens/TakeTour/ReportsTutorial";

import MyInbox from "screens/MyAccount/Inbox";
import MyWorkFolder from "screens/MyAccount/MyWorkFolder";
import MyFeedback from "screens/MyAccount/MyFeedBack";
import ChangePassword from "screens/MyAccount/ChangePassword";

import BroadCast from "screens/MyAccount/BroadCast";
import CreateBroadCast from "screens/MyAccount/BroadCast/Create";

// constants
import {
  defaultPermissions,
  defaultEntryPermissions,
  defaultAdminPermissions,
  defaultPaperGeneratePermissions,
} from "constant";
import Students from "screens/QuizManagement/Students";

export const privateRoutes = [
  // --------------------------------------------------------------------
  // DASHBOARD ROUTES
  {
    permissions: defaultPermissions,
    path: "/",
    component: Dashboard,
  },
  {
    permissions: defaultPermissions,
    path: "/about-us",
    component: AboutUs,
  },

  // --------------------------------------------------------------------
  // TAKE TOUR ROUTES
  {
    permissions: defaultPaperGeneratePermissions,
    path: "/taketour/lodge-complaint",
    component: LodgeComplaintTutorial,
  },
  {
    permissions: defaultPaperGeneratePermissions,
    path: "/taketour/rate-review",
    component: RateReviewTurotial,
  },
  {
    permissions: defaultPaperGeneratePermissions,
    path: "/taketour/paper-generation",
    component: PaperGenerationTurotial,
  },
  {
    permissions: defaultPaperGeneratePermissions,
    path: "/taketour/reports-tutorial",
    component: ReportsTutorial,
  },
  // --------------------------------------------------------------------
  // School Teacher Create ROUTERS
  {
    permissions: defaultPaperGeneratePermissions,
    path: "/create-new-teacher",
    component: CreateSchoolTeacherBSS,
  },

  // --------------------------------------------------------------------
  // ADMIN ROUTES
  {
    permissions: defaultAdminPermissions,
    path: "/campuses",
    component: Campus,
  },
  {
    permissions: defaultAdminPermissions,
    path: "/campuses/create",
    component: CreateCampus,
  },
  {
    permissions: defaultAdminPermissions,
    path: "/campuses/edit/:id",
    component: CreateCampus,
  },
  {
    permissions: defaultAdminPermissions,
    path: "/campus-users",
    component: CampusUser,
  },
  {
    permissions: defaultAdminPermissions,
    path: "/campus-users/create",
    component: CreateCampusUser,
  },
  {
    permissions: defaultAdminPermissions,
    path: "/campus-users/edit/:id",
    component: CreateCampusUser,
  },
  {
    permissions: defaultAdminPermissions,
    path: "/users",
    component: User,
  },
  {
    permissions: defaultAdminPermissions,
    path: "/users/create",
    component: CreateUser,
  },
  {
    permissions: defaultAdminPermissions,
    path: "/users/edit/:id",
    component: CreateUser,
  },
  {
    permissions: defaultAdminPermissions,
    path: "/broadcasts",
    component: BroadCast,
  },
  {
    permissions: defaultAdminPermissions,
    path: "/broadcasts/create",
    component: CreateBroadCast,
  },

  // --------------------------------------------------------------------
  // MY Account ROUTES
  {
    permissions: defaultEntryPermissions,
    path: "/my-feedbacks",
    component: MyFeedback,
  },
  {
    permissions: defaultPermissions,
    path: "/myinbox",
    component: MyInbox,
  },
  {
    permissions: defaultPermissions,
    path: "/complaints",
    component: Complaint,
  },
  {
    permissions: defaultPermissions,
    path: "/rate-reviews",
    component: RateReview,
  },
  {
    permissions: defaultPermissions,
    path: "/ask-questions",
    component: AskQuestion,
  },

  // --------------------------------------------------------------------
  // SUBJECT MANAGER ROUTES
  {
    permissions: defaultPermissions,
    path: "/chapters",
    component: Chapter,
  },
  {
    permissions: defaultEntryPermissions,
    path: "/chapters/create",
    component: CreateChapter,
  },
  {
    permissions: defaultEntryPermissions,
    path: "/chapters/edit/:id",
    component: CreateChapter,
  },
  {
    permissions: defaultPermissions,
    path: "/chapters/:id",
    component: ChapterDetail,
  },
  {
    permissions: defaultPermissions,
    path: "/topics",
    component: Topic,
  },
  {
    permissions: defaultEntryPermissions,
    path: "/topics/create",
    component: CreateTopic,
  },
  {
    permissions: defaultEntryPermissions,
    path: "/topics/edit/:id",
    component: CreateTopic,
  },
  {
    permissions: defaultPermissions,
    path: "/topics/:id",
    component: TopicDetail,
  },
  {
    permissions: defaultPermissions,
    path: "/mcqs",
    component: MCQS,
  },
  {
    permissions: defaultEntryPermissions,
    path: "/mcqs/create",
    component: CreateMCQS,
  },
  {
    permissions: defaultEntryPermissions,
    path: "/mcqs/edit/:id",
    component: CreateMCQS,
  },
  {
    permissions: defaultPermissions,
    path: "/mcqs/:id",
    component: MCQDetail,
  },
  {
    permissions: defaultPermissions,
    path: "/questions",
    component: Question,
  },
  {
    permissions: defaultEntryPermissions,
    path: "/questions/create",
    component: CreateQuestion,
  },
  {
    permissions: defaultEntryPermissions,
    path: "/questions/edit/:id",
    component: CreateQuestion,
  },
  {
    permissions: defaultPermissions,
    path: "/questions/:id",
    component: QuestionDetail,
  },

  // --------------------------------------------------------------------
  // PAPER MANAGER ROUTES
  {
    permissions: defaultPaperGeneratePermissions,
    path: "/papers",
    component: Papers,
  },
  {
    permissions: defaultPaperGeneratePermissions,
    path: "/papers/create",
    component: CreatePaper,
  },
  {
    permissions: defaultPaperGeneratePermissions,
    path: "/papers/:id",
    component: PaperDetail,
  },

  // --------------------------------------------------------------------
  // Quizes REPORTS ROUTES add student

  {
    permissions: defaultPaperGeneratePermissions,
    path: "/students",
    component: Students,
  },

  // --------------------------------------------------------------------
  // PERFORMANCE REPORTS ROUTES
  {
    permissions: defaultPaperGeneratePermissions,
    path: "/reports/papers",
    component: PaperReports,
  },

  // --------------------------------------------------------------------
  // FEEDBACK & REVIEWS ROUTES
  {
    permissions: defaultPermissions,
    path: "/feedback/complaints",
    component: FeedbackComplaint,
  },
  {
    permissions: defaultPermissions,
    path: "/feedback/rate-reviews",
    component: FeedbackRateReview,
  },
  {
    permissions: defaultPermissions,
    path: "/feedback/ask-questions",
    component: FeedbackAskQuestion,
  },

  // --------------------------------------------------------------------
  // SETTINGS ROUTES
  {
    permissions: defaultPermissions,
    path: "/change-password",
    component: ChangePassword,
  },

  // --------------------------------------------------------------------
  // UNKNOWN ROUTES
  {
    permissions: defaultAdminPermissions,
    path: "/workfolder",
    component: MyWorkFolder,
  },
];
