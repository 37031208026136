import client from "../api-config";

export function getTopStudents(instituteType, board, sClasses, campuses) {
  return client.get(
    `/api/v2/admin/top-students?instituteType=${instituteType}&boardType=${
      board ? board : ""
    }&classType=${sClasses ? sClasses : ""}&campusType=${
      campuses ? campuses : ""
    }`
  );
}

export function getAllCampuses(boardId) {
  return client.get(`/api/v2/allcampus/${boardId}`);
}

export function deleteStudent(id) {
  return client.delete(`/api/v2/admin/delete/students/${id}`);
}
