// third party
import { useQuery, useMutation } from "@tanstack/react-query";
import { deleteStudent, getAllCampuses, getTopStudents } from "api/v1/students";

// hooks
import { getBoards } from "api/v1/subject";

// custom hook
import { useToaster } from "hooks/custom/useToaster";

export const useGetTopStudents = (instituteType, board , sClasses, campuses) => { 
  return useQuery({
    queryKey: ["students"],
    queryFn: () => getTopStudents(instituteType, board , sClasses, campuses),
    refetchOnWindowFocus: false,
  }); 
};

export const useGetBoardCampus = (boardId) => {  
  return useQuery({
    queryKey: ["campus"],
    queryFn: () => getAllCampuses(boardId),
    refetchOnWindowFocus: false,
  }); 
};



export const useDeleteStudent = (refetch) => {
  const { toasterError, toasterSuccess } = useToaster();
  return useMutation({
    mutationFn: deleteStudent,
    onSuccess: () => {
      refetch();
      toasterSuccess("Student Deleted!");
    },
    onError: (err) => toasterError(`${err.errMsg}`),
  });
};
